import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import { Subscription, interval } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  /**
   * Authentication status
   */
  authenticated = false;
  /**
   * Role of the logged in user
   */
  role = '';
  /**
   * ID of the logged in user
   */
  userID = '';
  unreadCount: number = 0;
  /**
   * Subscription to the heartbeat interval
   */
  private heartbeatSubscription: Subscription;
  /**
   * Subscription to the unread count interval
   */
  private unreadCountSubscription: Subscription;
  private _redirectUrl: string = '';
  _id: string = '';
  firstname: string = '';
  lastname: string = '';

  /**
   * Adds dependencies to the service
   */
  constructor(
    private notify: NotificationService,
    private api: ApiService
  ) { 
    // Retrieve authentication status and user information from storage if available
    const storedRole = localStorage.getItem('role');
    const storedUserID = this.decrypt(localStorage.getItem('userID'));
    if (storedRole && storedUserID) {
      this.role = storedRole;
      this.userID = storedUserID;
      this.authenticated = true;
      this.startHeartbeat();
      this.startUnreadCount();
    }
  }

  /**
   * Checks if the user is authenticated
   * @returns True/False
   */
  public isAuthenticated(): boolean {
    return this.authenticated;
  }

  /**
   * Checks if the logged in user is a client
   * @returns True/False
   */
  public isClient(): boolean {
    let client = false;
    this.role === 'client' ? client = true : client = false;
    return client;
  }

  /**
   * Checks if the logged in user is a freelancer
   * @returns True/False
   */
  public isFreelancer(): boolean {
    let freelancer = false;
    this.role === 'freelancer' ? freelancer = true : freelancer = false;
    return freelancer;
  }

  /**
   * Checks if the logged in user is an admin
   * @returns True/False
   */
  public isAdmin(): boolean {
    let admin = false;
    this.role === 'admin' ? admin = true : admin = false;
    return admin;
  }

  /**
   * Authenticates a user
   * @param id - The id of the user to be authenticated
   */
  public authenticate(id: string): void {
    this.authenticated = true;
    this.userID = id;
    this.startHeartbeat();
    this.startUnreadCount();
  }

  /**
   * Logs out the user
   */
  public logout(): void {
    this.authenticated = false;
    this.role = '';
    this.userID = '';
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('userID');
    this.notify.showSuccess('Logged out successfully.');

    // Stop the heartbeat
    if (this.heartbeatSubscription) {
      this.heartbeatSubscription.unsubscribe();
    }

    // Stop unread count
    if (this.unreadCountSubscription) {
      this.unreadCountSubscription.unsubscribe();
    }
  }

  private sendHeartbeat() {
    this.api.post('/users/heartbeat', JSON.parse(JSON.stringify({userID: this.userID}))).subscribe();
  }

  // Send heartbeat every 3 seconds
  public startHeartbeat(): void {
    this.heartbeatSubscription = interval(3000).subscribe(() => {
      this.sendHeartbeat();
    });
  }

  public startUnreadCount(): void {
    // Unsubscribe from any previous unreadCountSubscription to prevent multiple subscriptions
    if (this.unreadCountSubscription) {
      this.unreadCountSubscription.unsubscribe();
    }
  
    if (this.userID) {
      this.unreadCountSubscription = interval(5000).subscribe(() => {
        this.getUnreadCount();
      });
    }
  }
  

  getUnreadCount(): void {
    this.api.get('/chats/unread-messages').subscribe({
      next: response => {
        if (response.status_code === 200) {
          this.unreadCount = response.detail;
        }
      },
      error: (e) => {
        this.notify.showError(e);
      }
    })
  }

  public get redirectUrl(): string {
    return this._redirectUrl;
  }

  public set redirectUrl(url: string) {
    this._redirectUrl = url;
  }

  public encrypt(input: string): string {
    const getRandomChars = (length: number) => {
      let result = '';
      const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charactersLength);
        result += characters.charAt(randomIndex);
      }
      return result;
    };
  
    const prefix = getRandomChars(4);
    const suffix = getRandomChars(4);
    
    return prefix + input + suffix;
  }

  public decrypt(input: string | null): string | null {
    if (input) {
      const originalLength = input.length - 8; // Remove 4 characters from start and end
      return input.substring(4, 4 + originalLength);
    }
    return null;
  }

}
