<div class="footer">
  <div class="container">
    <div class="row">
      <div class="col-md-3 mb-3 text">
        <p><a routerLink="/contact-us">Contact Us</a></p>
        <p><a routerLink="/about">About Us</a></p>
        <p><a routerLink="/faqs">FAQs</a></p>
      </div>
      <div class="col-md-3 mb-3 text">
        <p><a routerLink="/terms-and-conditions">Terms & Conditions</a></p>
        <p><a routerLink="/privacy-policy">Privacy Policy</a></p>
        <p><a href="https://blog.kaziflex.com" target="_blank">Blog</a></p>
      </div>
      <div class="col-md-2 mb-3 text">
        <p><a routerLink="/register">Sign Up</a></p>
        <p><a routerLink="/login">Log in</a></p>
      </div>
      <div class="col-md-4 mb-3">
        <p class="text-center">
          <a href="https://x.com/kaziflex" target="_blank"><fa-icon [icon]="twitterIcon" size="2x"></fa-icon></a>
          <a href="https://www.reddit.com/user/KaziFlex/" target="_blank"><fa-icon [icon]="redditIcon" size="2x"></fa-icon></a>
          <a href="https://www.instagram.com/kaziflex_official" target="_blank"><fa-icon [icon]="instagramIcon" size="2x"></fa-icon></a>
          <a href="https://www.linkedin.com/company/kazi-flex/" target="_blank"><fa-icon [icon]="linkedInIcon" size="2x"></fa-icon></a>
        </p>
      </div>
    </div>
  </div>
</div>