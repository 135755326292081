import { FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';

/**
 * Custom validator to check that two input fields match
 */
export function MustMatch(controlName: string, matchingControlName: string): ValidatorFn | null | ValidationErrors {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const control = formGroup.get(controlName);
    const matchingControl = formGroup.get(matchingControlName);

    if (!control || !matchingControl) {
      // Return null if the controls are not found
      return null;
    }

    if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
      // Return null if another validator has already found an error on the matchingControl
      return null;
    }

    // Set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
      return { mustMatch: true };
    } else {
      matchingControl.setErrors(null);
      return null;
    }
  };
}
