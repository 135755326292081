import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../core/services/authentication.service';
/**
 * Auth Guard to restrict access to routes based on user's role and authentication
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  /**
   * Adds dependencies to the component
   * @param router - Router for navigation to other route
   * @param authService - Authentication Service that contains the authentication information
   */
  constructor(private authService: AuthenticationService, private router: Router) {}
  /**
   * Interface that a class can implement to be a guard deciding if a route can be activated.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Checks if user is authorized to access a route
    if (this.authService.isAuthenticated()) {
      if (route.data['roles'] && route.data['roles'].indexOf(this.authService.role) === -1) {
        this.router.navigate(['/login']);
        return false;
      }
      return true;
    }
    /**
     * If a user is not authenticated or does not have the role required
     * to access a route, they are redirected to the login page
     */
    this.router.navigate(['login']);
    return false;
  }
}
