import { Component } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Role } from '../../models/role.model';

// Declare bootstrap globally
declare var bootstrap: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})
export class NavbarComponent {
  /**
   * User icon
   */
  userIcon = faUser;
  /**
   * User icon
   */
  userPlusIcon = faUserPlus;
  /**
   * User icon
   */
  userCircleIcon = faUserCircle;
  /**
   * Navbar menu icon
   */
  farBars = faBars;
  /**
   * Sign out icon
   */
  signOutIcon = faSignOutAlt;
  /**
   * Adds dependencies into the component
   */
  constructor(
    public authService: AuthenticationService
  ) { }

  /**
   * Checks if the logged in user is a Freelancer
   *
   * @returns True/False
   */
  get isFreelancer(): boolean {
    return this.authService.role === Role.Freelancer;
  }

  /**
   * Checks if the logged in user is a Client
   *
   * @returns True/False
   */
  get isClient(): boolean {
    return this.authService.role === Role.Client;
  }

  /**
   * Checks if the logged in user is an admin
   *
   * @returns True/False
   */
  get isAdmin(): boolean {
    return this.authService.role === Role.Admin;
  }

  hideSidebar(): void {
    const offcanvasElement = document.getElementById('offcanvasNavbar');
    const offcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
    offcanvas.hide(); // Hide the offcanvas after filtering
  }

}
