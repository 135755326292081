import { User } from '../../Interfaces/User';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { MustMatch } from '../../custom-validators/mustMatch';
import { ApiService } from '../../../core/services/api.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { NotificationService } from '../../../core/services/notification.service';

/**
 * The Account component enables a user to manage their account
 * It allows the user to:
 *  1. Update their first name and last name
 *  2. Change their password
 *  3. Change their timezone
 */
@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  /**
   * Form with fields for account details
   */
  accountForm: FormGroup;
  /**
   * Form with fields for setting the timezone
   */
  timezoneForm: FormGroup;
  /**
   * Form with fields for changing the password
   */
  passwordForm: FormGroup;
  /**
   * Form with fields for deleting an account
   */
  deleteForm: FormGroup;
  /**
   * Form with fields for privacy settings
   */
  privacyForm: FormGroup;
  /**
   * The logged in user
   */
  user: User;
  /**
   * Used to display spinner when waiting for the user details to be fetched from the Backend
   */
  isLoading: boolean;
  /**
   * Used to display spinner when waiting for the user details to be updated
   */
  isLoading1 = false;
  /**
   * Used to display spinner when waiting for the user password to be updated
   */
  loading = false;
  /**
   * Used for form validation and displaying validation errors
   */
  submitted = false;
  /**
   * Used on the password fields to hide or show passwords
   */
  fieldTextType: boolean;
  /**
   * Stores the state of the expansion panel
   */
  panelOpenState = false;
  /**
   * Used to display spinner when waiting for the profile picture to be removed
   */
  deleteLoading: boolean;

  /**
   * Adds dependencies into the component.
   * @param formBuilder - Initializes the form fields
   * @param api - API service
   * @param notify - Notification service for displaying toast messages
   * @param authService - Authentication service
   */
  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private notify: NotificationService,
    private authService: AuthenticationService,
    private cd: ChangeDetectorRef,
  ) { }

  /**
   * Fetches the timezones, currently logged in user and user's profile picture when component is initialized
   * Initializes the forms with the default values
   */
  ngOnInit(): void {
    this.fetchUser();
    this.accountForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$')]]
    });

    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
    this.passwordForm = this.formBuilder.group({
      current_pwd: ['', Validators.required],
      new_pwd: ['', [Validators.required, Validators.pattern(strongPassword)]],
      confirm_pwd: ['', Validators.required]
    },
    {
      validator: MustMatch('new_pwd', 'confirm_pwd')
    });

    this.privacyForm = this.formBuilder.group({
      download_password: [''],
      erase_password: ['']
    });

    this.deleteForm = this.formBuilder.group({
      password: [''],
    });
  }

  /**
   * Gets all the controls of accountForm
   */
  get g(): FormGroup['controls'] {
    return this.accountForm.controls;
  }

  /**
   * Gets all the controls of passwordForm
   */
  get f(): FormGroup['controls'] {
    return this.passwordForm.controls;
  }


  /**
   * Fetches the logged in user details
   */
  fetchUser(): void {
    this.isLoading = true;
    this.api.get('/users/' + this.authService.userID).subscribe(
      res => {
        if (res.status_code === 200) {
          this.isLoading = false;
          this.user = res.detail;
          this.accountForm.get('firstname')?.setValue(res.detail.firstname);
          this.accountForm.get('lastname')?.setValue(res.detail.lastname);
          this.accountForm.get('email')?.setValue(res.detail.email);
        } else {
          this.notify.showError(res.detail);
          this.isLoading = false;
        }
      },
      err => {
        this.isLoading = false;
      }
    );
  }

  /**
   * Uploads a profile picture
   * @param event - Object with the file to be uploaded
   */
  uploadFile(event: any): void {
    const reader = new FileReader(); // HTML5 FileReader API
    const file = event.target.files[0];

    if (file.size / 1000000 > 5 && event.target.files && event.target.files[0]) {
      this.notify.showError('The image must be less than 5MB');
    }

    if (file.size / 1000000 < 5 && event.target.files && event.target.files[0]) {
      this.loading = true;
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        const formData = new FormData();
        formData.append('image', file);
        this.api.postFormData('/users/set-profile-picture/' + this.authService.userID, formData).subscribe(
          res => {
            if (res.status_code === 200) {
              this.notify.showSuccess('Profile picture set successfully.');
              this.loading = false;
              this.fetchUser();
            } else {
              this.notify.showError(res.detail);
              this.loading = false;
            }
          },
          err => {
            this.loading = false;
            this.notify.showSuccess('Profile picture was not set successfully.');
          }
        );
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  /**
   * Removes the user's profile picture
   */
  removeProfilePicture(): void {
    this.deleteLoading = true;
    this.api.deleteNoBody('/users/delete-profile-picture/' + this.user._id).subscribe({
      next: res => {
        if (res.status_code === 200) {
          this.notify.showSuccess('Profile picture successfuly removed');
          this.deleteLoading = false;
          this.fetchUser();
        } else {
          this.notify.showError(res.detail);
          this.deleteLoading = false;
        }
      },
      error: (e) => {
        this.notify.showError(e);
        this.deleteLoading = false;
      } 
    });
  }

  /**
   * Updates a user's password through an API endpoint
   */
  updatePassword(): void {
    this.submitted = true;
    if (this.passwordForm.invalid) {
      return;
    }

    this.loading = true;

    const user = {
      user_id: this.authService.userID,
      old_password: this.passwordForm.get('current_pwd')?.value,
      new_password: this.passwordForm.get('new_pwd')?.value
    };

    this.api.put('/users/change-pwd', JSON.parse(JSON.stringify(user))).subscribe(
      res => {
        if (res.status_code === 200) {
          this.notify.showSuccess('Passsword changed successfully');
          this.loading = false;
          this.passwordForm.reset();
          this.submitted = false;
        } else {
          this.notify.showError(res.detail);
          this.loading = false;
        }
      }
    );
  }

  /**
   * Updates a user's details through an API endpoint
   */
  updateInfo(): void {
    if (this.accountForm.invalid) {
      return;
    }
    this.isLoading1 = true;
    const updatedUser = {
      user_id: this.user._id,
      firstname: this.accountForm.get('firstname')?.value,
      lastname: this.accountForm.get('lastname')?.value,
    };
    this.api.put('/users/set-infos', JSON.parse(JSON.stringify(updatedUser))).subscribe(
      res => {
        if (res.status_code === 200) {
          this.notify.showSuccess('User details have been set successfully.');
          this.accountForm.reset();
          this.fetchUser();
          this.isLoading1 = false;
        } else {
          this.notify.showError(res.detail);
          this.isLoading1 = false;
        }
      }
    );
  }

  /**
   * Toggles the password input type from text to password and vice versa when user clicks the show password icon
   */
  toggle(): void {
    this.fieldTextType = !this.fieldTextType;
  }
}
