<div class="container-fluid">
    <div class="row">
        <!-- Sidebar Toggle Button for Mobile -->
        <div class="col-12 d-md-none mb-3">
            <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSidebar" aria-controls="offcanvasSidebar">
                Display Chats
            </button>
        </div>
        
        <!-- Offcanvas Sidebar -->
        <div class="offcanvas offcanvas-start d-md-none" tabindex="-1" id="offcanvasSidebar" aria-labelledby="offcanvasSidebarLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasSidebarLabel">Messages</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <div *ngIf="fetchingRecipients || fetchingRecipient" class="d-flex justify-content-center">
                    <app-loading></app-loading>
                </div>
                <div class="recipients" *ngIf="!fetchingRecipients && !fetchingRecipient">
                    <h6 *ngIf="unreadCount > 0">Total Unread: {{unreadCount}}</h6>
                    <div class="card"
                        *ngFor="let recipient of recipients" (click)="getConversation(recipient)" 
                        [ngStyle]="{'background-color': recipientId == recipient._id ? '#20cd8d' : '#edf3f7'}" data-bs-dismiss="offcanvas">
                        <div class="card-body">
                            <p>{{recipient.firstname}} {{recipient.lastname}} <span *ngIf="recipient.unread > 0">{{recipient.unread}}</span></p>
                            <p [ngClass]="{ 'online': recipient.online, 'offline': !recipient.online }">{{ recipient.online ? 'Online' : 'Offline' }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Sidebar for Larger Screens -->
        <div class="col-md-2 d-none d-md-block left">
            <div *ngIf="fetchingRecipients || fetchingRecipient" class="d-flex justify-content-center">
                <app-loading></app-loading>
            </div>
            <div class="recipients" *ngIf="!fetchingRecipients && !fetchingRecipient">
                <h3>Messages</h3>
                <h6 *ngIf="unreadCount > 0">Total Unread: {{unreadCount}}</h6>
                <div class="card"
                    *ngFor="let recipient of recipients" (click)="getConversation(recipient)" 
                    [ngStyle]="{'background-color': recipientId == recipient._id ? '#20cd8d' : '#edf3f7'}">
                    <div class="card-body">
                        <p>{{recipient.firstname}} {{recipient.lastname}} <span *ngIf="recipient.unread > 0">{{recipient.unread}}</span></p>
                        <p [ngClass]="{ 'online': recipient.online, 'offline': !recipient.online }">{{ recipient.online ? 'Online' : 'Offline' }}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Main Content Area -->
        <div class="col-md-10 right">
            <div #scrollableDiv class="chats">
                <!-- Sticky Header -->
                <div class="sticky-top bg-white py-2" *ngIf="recipientId" style="z-index: 1;">
                    <h3 class="mb-3">{{selectedRecipient}}</h3>
                </div>

                <!-- Chat Messages -->
                <div *ngIf="fetchingConversation" class="d-flex justify-content-center">
                    <app-loading></app-loading>
                </div>
                <div *ngIf="recipientId">
                    <div *ngFor="let chat of readChats">
                        <div *ngIf="chat.sender === userId">
                            <p class="person"><strong>Me </strong> <span>{{chat.date | date: 'medium'}}</span></p>
                            <p class="message">{{ chat.message }}</p>
                        </div>
                        <div *ngIf="chat.sender != userId">
                            <p class="person"><strong>{{selectedRecipient}} </strong> <span>{{chat.date | date: 'medium'}}</span></p>
                            <p class="message">{{ chat.message }}</p>
                        </div>
                        <hr>
                    </div>
                    <div class="unread" *ngIf="unreadChats.length > 0">
                        <div *ngFor="let chat of unreadChats">
                            <div *ngIf="chat.sender === userId">
                                <p class="person"><strong>Me </strong> <span>{{chat.date | date: 'medium'}}</span></p>
                                <p class="message">{{ chat.message }}</p>
                            </div>
                            <div *ngIf="chat.sender != userId">
                                <p class="person"><strong>{{selectedRecipient}} </strong> <span>{{chat.date | date: 'medium'}}</span> <span class="new">NEW</span></p>
                                <p class="message">{{ chat.message }}</p>
                            </div>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Message Form -->
            <div class="form">
                <form [formGroup]="messageForm">
                    <div class="row" *ngIf="recipientId">
                        <div class="form-group col-md-12 mb-3">
                            <div class="input-group">
                                <input type="text" class="form-control" formControlName="message"
                                    [ngClass]="{ 'is-invalid': submitted && f['message'].errors}" autofocus>
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <fa-icon [icon]="sendIcon" (click)="sendMessage()"></fa-icon> 
                                    </span>
                                </div>
                                <div *ngIf="submitted && f['message'].errors" class="invalid-feedback">
                                    <div *ngIf="f['message']['errors']required">Message is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
