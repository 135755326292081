<div class="account">
    <div class="container">
        <div class="row">
            <div class="col-md-4 mb-5">
                <div class="d-flex justify-content-center" *ngIf="isLoading">
                    <mat-spinner diameter="20" style="margin-top: 20px; margin-bottom: 20px;"></mat-spinner>
                </div>
                <div class="avatar" *ngIf="!isLoading">
                    <div class="avatar-upload">
                        <div class="avatar-edit">
                            <input type="file" id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
                                (change)="uploadFile($event)" />
                        </div>
                        <div class="avatar-preview">
                            <div id="imagePreview" [style.backgroundImage]="'url(' + user.profile_image + ')'"></div>
                        </div>
                    </div>
                    <div class="photo">
                        <p class="text-center">Supported types: PNG, JPG, JPEG</p>
                        <p class="text-center">Max size: 5MB</p>
                    </div>
                    <div class="photo-buttons">
                        <button class="d-flex btn btn-info btn-block" (click)="fileInput.click()" [disabled]="loading"
                            *ngIf="user.profile_image == './assets/images/default_avatar.jpg'">
                            UPLOAD PICTURE
                            <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true">
                            </span>
                        </button>
                        <button class="d-flex btn btn-info btn-block" (click)="removeProfilePicture()"
                            *ngIf="user.profile_image != './assets/images/default_avatar.jpg'" [disabled]="deleteLoading">
                            REMOVE PICTURE
                            <span *ngIf="deleteLoading" class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true">
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-8 mb-5">
                <mat-accordion>
                    <!-- ACCOUNT DROPDOWN -->
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Account
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <form [formGroup]="accountForm">
                            <div class="form-group col-md-12 mb-3">
                                <label for="firstname">First Name</label>
                                <input type="text" class="form-control" formControlName="firstname"
                                    [ngClass]="{ 'is-invalid': submitted && g['firstname'].errors}">
                                <div *ngIf="submitted && g['firstname'].errors" class="invalid-feedback">
                                    <div *ngIf="g['firstname']['errors']required">Firstname is required</div>
                                </div>
                            </div>
                            <div class="form-group col-md-12 mb-3">
                                <label for="firstname">Last Name</label>
                                <input type="text" class="form-control" formControlName="lastname"
                                    [ngClass]="{ 'is-invalid': submitted && g['lastname'].errors}">
                                <div *ngIf="submitted && g['lastname'].errors" class="invalid-feedback">
                                    <div *ngIf="g['lastname']['errors']required">Lastname is required</div>
                                </div>
                            </div>
                            <div class="form-group col-md-12 mb-4">
                                <label for="firstname">Email Address</label>
                                <input type="email" class="form-control" formControlName="email"
                                    [ngClass]="{ 'is-invalid': submitted && g['email'].errors}" readonly>
                                <div *ngIf=" submitted && g['email'].errors" class="invalid-feedback">
                                    <div *ngIf="g['email']['errors']required">Email is required</div>
                                    <div *ngIf="g['email']['errors']pattern">Input a valid email in lowercase</div>
                                </div>
                            </div>
                            <div class="form-group col-md-6">
                                <button class="btn btn-info" (click)="updateInfo()" [disabled]="isLoading1">UPDATE ACCOUNT
                                    <span *ngIf="isLoading1" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </form>
                    </mat-expansion-panel>


                    <!-- PASSWORD -->
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Change Password
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <form [formGroup]="passwordForm">
                            <div class="form-group col-md-12 mb-2">
                                <label for="current_pwd">Current Password</label>
                                <div class="input-group">
                                    <input [type]="fieldTextType? 'text' : 'password'" class="form-control"
                                        formControlName="current_pwd"
                                        [ngClass]="{ 'is-invalid': submitted && f['current_pwd'].errors}">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <mat-icon class="icon" *ngIf="fieldTextType"
                                                (click)="toggle()">visibility</mat-icon>
                                            <mat-icon class="icon" *ngIf="!fieldTextType"
                                                (click)="toggle()">visibility_off</mat-icon>
                                        </span>
                                    </div>
                                    <div *ngIf="submitted && f['current_pwd'].errors" class="invalid-feedback">
                                        <div *ngIf="f['current_pwd']['errors']required">Current password is required
                                        </div>
                                        <div *ngIf="f['current_pwd']['errors']minlength">Must be a minimum of 6
                                            characters</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-12 mb-2">
                                <label for="new_pwd">New Password</label>
                                <div class="input-group">
                                    <input [type]="fieldTextType? 'text' : 'password'" class="form-control"
                                        formControlName="new_pwd"
                                        [ngClass]="{ 'is-invalid': submitted && f['new_pwd'].errors}">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <mat-icon class="icon" *ngIf="fieldTextType"
                                                (click)="toggle()">visibility</mat-icon>
                                            <mat-icon class="icon" *ngIf="!fieldTextType"
                                                (click)="toggle()">visibility_off</mat-icon>
                                        </span>
                                    </div>
                                    <div *ngIf="submitted && f['new_pwd'].errors" class="invalid-feedback">
                                        <div *ngIf="f['new_pwd']['errors']required">New password is required</div>
                                        <div *ngIf="f['new_pwd']['errors']pattern">
                                            <p>Password must have a minimum of 8 characters.</p>
                                            <p>Password must have at least one uppercase letter, one lowercase letter,
                                                one digit and one
                                                special character.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-12 mb-4">
                                <label for="confirm_pwd">Confirm Password</label>
                                <div class="input-group">
                                    <input [type]="fieldTextType? 'text' : 'password'" class="form-control"
                                        formControlName="confirm_pwd"
                                        [ngClass]="{ 'is-invalid': submitted && f['confirm_pwd'].errors}">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">
                                            <mat-icon class="icon" *ngIf="fieldTextType"
                                                (click)="toggle()">visibility</mat-icon>
                                            <mat-icon class="icon" *ngIf="!fieldTextType"
                                                (click)="toggle()">visibility_off</mat-icon>
                                        </span>
                                    </div>
                                    <div *ngIf="submitted && f['confirm_pwd'].errors" class="invalid-feedback">
                                        <div *ngIf="f['confirm_pwd']['errors']required">Confirmation of password is
                                            required</div>
                                        <div *ngIf="f['confirm_pwd']['errors']mustMatch">Passwords must match</div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-12">
                                <button class="btn btn-info" (click)="updatePassword()" [disabled]="loading">UPDATE PASSWORD
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </form>
                    </mat-expansion-panel>

                    <!-- PRIVACY DROPDOWN -->
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Privacy
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <form [formGroup]="privacyForm">
                            <div class="form-group col-md-12">
                                <label for="profile privacy">Profile Privacy</label>
                                <select class="form-control" id="exampleFormControlSelect1">
                                    <option>Everyone</option>
                                    <option>Only me</option>
                                </select>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="email">Hide my profile from directory</label>
                                <mat-radio-group aria-label="Select an option">
                                    <mat-radio-button value="1">No</mat-radio-button>
                                    <mat-radio-button value="2">Yes</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="firstname">Download your data</label>
                                <p>Enter a new password to confirm a new export of your personal data</p>
                                <input type="text" class="form-control" formControlName="download_password"
                                    placeholder="Password">
                                <button class="btn btn-info">Request data</button>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="firstname">Erase your data</label>
                                <p>Enter your current password to confirm the erase of your personal data</p>
                                <input type="text" class="form-control" formControlName="erase_password"
                                    placeholder="Password">
                                <button class="btn btn-info">Request data erase</button>
                            </div>
                            <div class="form-group col-md-12 button">
                                <button class="btn btn-info">UPDATE PRIVACY</button>
                            </div>
                        </form>
                    </mat-expansion-panel>

                    <!-- DELETE ACCOUNT DROPDOWN -->
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Delete Account
                            </mat-panel-title>
                            <mat-panel-description>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <p class="info">
                            Are you sure you want to delete your account?
                            This will erase all of your account data from the site. To delete your account enter your
                            password below
                        </p>
                        <form [formGroup]="deleteForm">
                            <div class="form-group col-md-12">
                                <label for="password">Password</label>
                                <input type="text" class="form-control" formControlName="password">
                            </div>
                            <div class="form-group col-md-12">
                                <button class="btn btn-info" disabled>DELETE ACCOUNT</button>
                            </div>
                        </form>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>