import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { WebsocketService } from '../../../core/services/websocket.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from '../../../core/services/api.service';
import { NotificationService } from '../../../core/services/notification.service';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { Message } from '../../Interfaces/Message';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrl: './messages.component.scss'
})
export class MessagesComponent implements OnInit, OnDestroy {
  /**
   * The message form.
   */
  messageForm: FormGroup;
  /**
   * Used for form validation and displaying errors
   */
  submitted = false;
  /**
   * Send message icon
   */
  sendIcon = faPaperPlane;
  userId: string | null;
  recipientId: string | null;
  private websocketSubscription: Subscription;
  recipients: any[] = [];
  fetchingRecipients: boolean;
  fetchingRecipient: boolean;
  selectedRecipient: string;
  fetchingConversation: boolean;
  readChats: Message[] = [];
  unreadChats: Message[] = [];
  unreadCount: number = 0;

  @ViewChild('scrollableDiv', { read: ElementRef }) public scrollableDiv: ElementRef<any>;

  constructor(
    private websocketService: WebsocketService,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private notify: NotificationService,
    public auth: AuthenticationService
  ) {}

  /**
   * Initializes the messageForm with the default values when component is iniatilized
   */
  ngOnInit(): void {
    if (localStorage.getItem('userID')) {
      this.userId = this.auth.decrypt(localStorage.getItem('userID'));
    } else {
      this.userId = null;
    }
    if (history.state._id) {
      delete history.state.navigationId;
      this.getRecipient(history.state._id)
      this.getConversation(history.state);
    }
    this.getRecipients();
    this.messageForm = this.formBuilder.group({
      message: ['', Validators.required],
    });
  }

  ngAfterViewChecked(): void {
    this.scrollToBottom()
  }

  scrollToBottom(): void {
    this.scrollableDiv.nativeElement.scrollTop = this.scrollableDiv.nativeElement.scrollHeight;
  }

  /**
   * Getter for all the controls of the messageForm
   */
  get f(): FormGroup['controls'] {
    return this.messageForm.controls;
  }

  connect(recipientId: string): void {
    this.websocketService.connect();
  
    this.websocketSubscription = this.websocketService.socket$.subscribe({
      next: (message) => {
        this.unreadChats.push(message);
      },
      error: (e) => {
        console.error('WebSocket error:', e);
      }
    });
  
    const message = {
      sender: this.userId,
      recipientId: recipientId,
    };
    this.websocketService.sendMessage(JSON.parse(JSON.stringify(message)));
  }

  sendMessage(): void {
    this.submitted = true;

    if (this.messageForm.invalid) {
      return;
    }

    if (!this.userId) {
      this.notify.showError('Log in to send a message.');
      return;
    }

    if (!this.recipientId) {
      this.notify.showError('Select a recipient to send a message.');
      return;
    }

    if (this.containsPersonalInformation(this.messageForm.get('message')?.value)) {
      this.notify.showError('The message contains personal information. Kindly remove personal information for the message to be sent.');
      return;
    }

    const message = {
      sender: this.userId,
      recipientId: this.recipientId,
      content: this.messageForm.get('message')?.value
    }

    this.websocketService.sendMessage(JSON.parse(JSON.stringify(message)));
    this.messageForm.reset();
    this.submitted = false;
  }

  containsPersonalInformation(message: string) {
    // Regular expression for email
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

    // Regular expression for phone numbers (generic patterns for demonstration)
    const phoneRegex = /(\b\d{3}[-.\s]??\d{3}[-.\s]??\d{4}\b)|(\b\d{3}[-.\s]??\d{4}\b)|(\b\d{10}\b)|(\(\d{3}\)\s*\d{3}[-.\s]??\d{4})/;

    // Check for emails
    if (emailRegex.test(message)) {
      return true;
    }

    // Check for phone numbers
    if (phoneRegex.test(message)) {
      return true;
    }

    return false;
  }

  ngOnDestroy(): void {
    this.closeWebSocketConnection();
  }

  private closeWebSocketConnection(): void {
    if (this.websocketSubscription) {
      this.websocketSubscription.unsubscribe();
    }
    this.websocketService.closeConnection();
  }

  getRecipients(): void {
    this.fetchingRecipients = true;
    this.api.get('/chats/recipients').subscribe({
      next: response => {
        if (response.status_code === 200) {
          this.recipients = this.recipients.concat(response.detail.recipients);
          this.unreadCount = response.detail.unread;
          this.recipients = this.recipients.filter((obj, index, self) =>
            index === self.findIndex((recipient) => (
              recipient._id === obj._id
            ))
          );
        }
        this.fetchingRecipients = false;
      },
      error: (e) => {
        this.fetchingRecipients = false;
        this.notify.showError(e);
      }
    });
  }

  getRecipient(id: string): void {
    this.fetchingRecipient = true;
    this.api.get(`/users/${id}`).subscribe({
      next: response => {
        if (response.status_code === 200) {
          const recipient = [
            {
              firstname: response.detail.firstname,
              lastname: response.detail.lastname,
              online: response.detail.online,
              undread: 0,
              _id: response.detail._id,
            }
          ]
          this.recipients = this.recipients.concat(recipient);
          this.recipients = this.recipients.filter((obj, index, self) =>
            index === self.findIndex((recipient) => (
              recipient._id === obj._id
            ))
          );
        }
        this.fetchingRecipient = false;
      },
      error: (e) => {
        this.fetchingRecipient = false;
        this.notify.showError(e);
      }
    });
  }

  getConversation(recipient: any): void {
    if (this.userId) {
      this.connect(recipient._id);
      this.readChats = [];
      this.unreadChats = [];
      this.recipientId = recipient._id;
      this.selectedRecipient = recipient.firstname + ' ' + recipient.lastname;
      this.fetchingConversation = true;
      this.api.getWithParams('/chats/conversation', this.userId, recipient._id).subscribe({
        next: response => {
          if (response.status_code === 200) {
            this.readChats = response.detail.read_messages;
            this.unreadChats = response.detail.unread_messages;
            if (this.auth.unreadCount > 0) {
              this.auth.unreadCount -= recipient.unread;
              this.unreadCount -= recipient.unread;
            }
          }
          this.fetchingConversation = false;
        },
        error: (e) => {
          this.fetchingConversation = false;
          this.readChats = [];
          this.unreadChats = [];
          this.notify.showError(e);
        }
      });
    }
  }

}
