import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  public socket$: WebSocketSubject<any>;
  private readonly websocketUrl = 'wss://d2bdxx9l9jkdhr.cloudfront.net/kaziflex-api/chats/ws';

  constructor() { }

  connect(): void {
    // Connect to the WebSocket server
    this.socket$ = webSocket(this.websocketUrl);
  }

  sendMessage(message: any): void {
    if (this.socket$) {
      this.socket$.next(message);
    } else {
      console.error('WebSocket is not connected.');
    }
  }

  closeConnection(): void {
    if (this.socket$) {
      this.socket$.complete(); // Complete the WebSocket connection
    }
  }
}

