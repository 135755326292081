// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Exports development environment and its variables
 */
export const environment = {
  production: false,
  baseUrl: 'https://d2bdxx9l9jkdhr.cloudfront.net/kaziflex-api',
  stripePublicKey: 'pk_test_51PISKmAe5BurJFVYRJqtckIpGdEF0T8p1oWaPLr6b7U16UM3R6DR6CuVCmBEScpGU9nhveh1Xff0nYPHBgPJpq7w00MYMsgxv9'
};
