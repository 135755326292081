import { Component } from '@angular/core';
import { faRedditAlien } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  /**
   * Facebook icon
   */
  redditIcon = faRedditAlien;
  /**
   * Instagram icon
   */
  instagramIcon = faInstagramSquare;
  /**
   * Twitter icon
   */
  twitterIcon = faXTwitter;
  /**
   * Twitter icon
   */
  linkedInIcon = faLinkedin;
}
