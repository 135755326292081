import { HttpHeaders, HttpClient, HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  /**
   * Optional Header to exclude authorization
   */
  noAuth = {
    headers: new HttpHeaders().set('no-auth', 'true')
  };
  /**
   * Optional Header with body
   */
  options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    body: { }
  };
  /**
   * Adds dependencies to the service
   */
  constructor(private http: HttpClient) { }
  /**
   * POST method that requires authentication using a token
   * @param endpoint - The endpoint to be appended to the baseURL
   * @param data - The data to be sent through the API
   */
  post(endpoint: string, data: JSON): Observable<any> {
    return this.http.post<any>(environment.baseUrl + endpoint, data);
  }

  /**
   * POST method that does not require authentication using a token
   * @param endpoint - The endpoint to be appended to the baseURL
   * @param data - The data to be sent through the API
   */
  postNoAuth(endpoint: string, data: JSON): Observable<any> {
    return this.http.post<any>(environment.baseUrl + endpoint, data, this.noAuth);
  }

  /**
   * GET method that requires authentication using a token
   * @param endpoint - The endpoint to be appended to the baseURL
   */
  get(endpoint: string): Observable<any> {
    return this.http.get<any>(environment.baseUrl + endpoint);
  }

  /**
   * GET method with query parameters that requires authentication using a token
   * @param endpoint - The endpoint to be appended to the baseURL
   */
  getWithParams(endpoint: string, sender: string, recipient: string): Observable<any> {
    const params = { sender, recipient };
    return this.http.get<any>(environment.baseUrl + endpoint, { params });
  }

  /**
   * GET method that does not require authentication using a token
   * @param endpoint - The endpoint to be appended to the baseURL
   */
  getNoAuth(endpoint: string): Observable<any> {
    return this.http.get<any>(environment.baseUrl + endpoint, this.noAuth);
  }

  /**
   * POST method that sends FormData
   * @param endpoint - The endpoint to be appended to the baseURL
   * @param data - The data to be sent through the API
   */
  postFormData(endpoint: string, data: FormData): Observable<any> {
    return this.http.post<any>(environment.baseUrl + endpoint, data);
  }

  /**
   * GET method for a file
   * Returns a blob response type
   * @param endpoint - The endpoint to be appended to the baseURL
   */
  getFile(endpoint: string): Observable<Blob> {
    return this.http.get(environment.baseUrl + endpoint, {responseType: 'blob'});
  }

  /**
   * Retrieves the profile picture from the specified endpoint.
   * 
   * @param endpoint The URL endpoint from which to fetch the profile picture.
   * @returns An Observable emitting an HttpResponse containing the Blob data of the profile picture.
   */
  getProfilePicture(endpoint: string): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(environment.baseUrl + endpoint, {
      responseType: 'blob' as 'json',
      observe: 'response'
    });
  }

  /**
   * PUT method that requires authentication using a token
   * @param endpoint - The endpoint to be appended to the baseURL
   * @param data - The data to be sent through the API
   */
  put(endpoint: string, data: JSON): Observable<any> {
    return this.http.put<any>(environment.baseUrl + endpoint, data);
  }

  /**
   * PUT method that does not require authentication using a token
   * @param endpoint - The endpoint to be appended to the baseURL
   * @param data - The data to be sent through the API
   */
  putNoAuth(endpoint: string, data: JSON): Observable<any> {
    return this.http.put<any>(environment.baseUrl + endpoint, data, this.noAuth);
  }

  /**
   * DELETE method with no body
   * @param endpoint - The endpoint to be appended to the baseURL
   */
  deleteNoBody(endpoint: string): Observable<any> {
    return this.http.delete<any>(environment.baseUrl + endpoint);
  }

  /**
   * DELETE method with body
   * @param endpoint - The endpoint to be appended to the baseURL
   * @param data - The data to be sent through the API
   */
  delete(endpoint: string, data: any): Observable<any> {
    this.options.body = data;
    return this.http.delete<any>(environment.baseUrl + endpoint, this.options);
  }

  upload(file: File, endpoint: string): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', environment.baseUrl + endpoint, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }
}
