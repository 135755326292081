import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

/**
 * Service to handle notifications through toast messages diaplayed on the screen
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  /**
   * Adds dependencies to the component
   * @param snackBar - Toast notification component from Angular Material
   */
  constructor(public snackBar: MatSnackBar) { }
  /**
   * Displays a toast message that has information
   * @param message - The messsage to be displayed
   */
  showInfo(message: string): void {
    this.snackBar.open(message, 'X', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'custom-snackbar'
    });
  }
  /**
   * Dislays a toast message that gives feedback when an operation is successful
   * @param message - The messsage to be displayed
   */
  showSuccess(message: string): void {
    this.snackBar.open(message, 'X', {
      duration: 4000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'custom-snackbar'
    });
  }

  /**
   * Dislays a toast message that gives feedback when an operation is not successful
   * @param message - The messsage to be displayed
   */
  showError(message: string): void {
    this.snackBar.open(message, 'X', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'custom-snackbar'
    });
  }
}
